.ui.form textarea,
.ui.form textarea:focus,
.ui.form input[type="text"],
.ui.form input[type="text"]:focus,
.ui.form input[type="number"],
.ui.form input[type="number"]:focus,
.ui.form input[type="password"],
.ui.form input[type="password"]:focus,
.ui.form input[type="email"],
.ui.form input[type="email"]:focus,
.ui.form .field > .ui.dropdown {
  border: none;
  border-bottom: solid 1px @spreeLightGrey;
  border-radius: 0;
  background: transparent !important;
  padding-left: 3pt;
  padding-right: 3pt;
}

.ui.form textarea:hover,
.ui.form input[type="text"]:hover,
.ui.form input[type="number"]:hover,
.ui.form input[type="password"]:hover,
.ui.form input[type="email"]:hover,
.ui.form .ui.dropdown:hover {
  border-bottom: solid 1px black;
}

.ui.form textarea:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="email"]:focus {
  border-bottom: solid 1px @spreeMuiActiveElement;
}

.ui.form .ui.dropdown.active {
  border: solid 1px #96c8da;
  border-radius: 3px 3px 0 0;
}
