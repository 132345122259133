/*******************************
         Theme Overrides
*******************************/

.ui.button {
    border: solid 2pt #131845;
}

.ui.red.buttons .button,
.ui.red.button {
    border-color: @red;
}
