/*******************************
         Site Overrides
*******************************/

.ui.grid.inline {
  margin: 0;

  & > .column:not(.row) {
    padding-top: 0;
  }
}
