/*******************************
         Site Overrides
*******************************/

.leftbar.ui.vertical.menu {
  border-right: none;
  .item {
    color: #07192D;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
