/*******************************
         Site Overrides
*******************************/

.ui.table.borderless {
    border: none;
}

.ui.table {
    color: #05192F;

    th {
        font-size: 11.25pt;
        font-weight: normal;
    }

    .lite {
        color: #687E95;
    }

    .id > span {
        border: solid 1px #398CF5;
        padding: 0.3em .7em;
        border-radius: 50%;
    }

    td.clickable {
        cursor: pointer;
    }

    tr.selected {
        background-color: #F2F7FA;
    }
}

.ui.table.sortable {
    th:hover {
        background-color: @pageBackground;
    }

    th.sortable {
        position: relative;
    }

    th.sortable:after {
        position: absolute;
        right: 0.4em;
        display: inline;
        font-family: 'Icons';
        content: '\f0dc';
        color: @sortableColor;
    }

    th.sorted:after {
        color: @spreeSolidBlue;
    }

    th.sorted.ascending:after {
        // content: '\f106';
        content: '\f0de';
    }

    th.sorted.descending:after {
        // content: '\f107';
        content: '\f0dd';
    }

    th.sortable.sorted,
    th.sortable:hover {
        background-color: darken(@pageBackground, 1%);
    }
}
